<template>
  <div id='screenshot-controls-container' class='p-2 no-top-margin-all-descendants'>
    <div class="modal-header px-0 pt-1 pb-2 d-flex flex-row justify-content-between align-items-center">
      <div class="d-flex flex-row justify-content-left align-items-center">
        <b-icon-grip-vertical />
        <h5 class="ml-1">Screenshot</h5>
      </div>
      <b-icon 
        icon='x'
        class="close-controls-button"
        @click="toggleScreenShotMode">
      </b-icon>
    </div>  
    <hr class="mb-2">
    <div class="d-flex flex-row-justify-content-between">
      <div>
        <b-form-select v-model="aspectRatio" class="screenshot-dropdown">
          <b-form-select-option :value="null">Aspect Ratio</b-form-select-option>
          <b-form-select-option value="3:2">3:2</b-form-select-option>
          <b-form-select-option value="4:3">4:3</b-form-select-option>
          <b-form-select-option value="16:9">16:9</b-form-select-option>
          <b-form-select-option value="custom">custom</b-form-select-option>
        </b-form-select>
      </div>

      <div class="ml-2">
        <b-form-select v-model="resolution" class="screenshot-dropdown" @change="setScreenShotSize">
          <b-form-select-option :value="null">Resolution</b-form-select-option>
          <b-form-select-option value="small">small</b-form-select-option>
          <b-form-select-option value="medium">medium</b-form-select-option>
          <b-form-select-option value="large">large</b-form-select-option>
        </b-form-select>
      </div>

      <div class="ml-2">
        <b-form-select v-model="screenshotType" class="screenshot-dropdown" @change="setScreenShotSize">
          <b-form-select-option :value="null">Screenshot Type</b-form-select-option>
          <b-form-select-option value="currentLayer">Current layer</b-form-select-option>
          <b-form-select-option v-if='!isSplitView' value="allLayers">All layers</b-form-select-option>
          <b-form-select-option value="projectImage">Set project image</b-form-select-option>
        </b-form-select>
      </div>
    </div>
    
    <div class="w-100 d-flex flex-row justify-content-between mt-2">
      <div v-if="aspectRatio === 'custom'">
          <b-form-input
          class='aspect-ratio-input'
          :value="aspectX"
          @change="setAspectX"
          >
          </b-form-input>
          <span
            class='aspect-ratio-colon'>
            :
          </span>
          <b-form-input
            class='aspect-ratio-input'
            :value="aspectY"
            @change="setAspectY">
          </b-form-input>
        </div>  
      <div class="mt-2 ml-1">
        <b-form-checkbox
          v-model="saveCamera"
          class="save-camera-checkbox"
          :disabled="onSavedCamera">
          save camera angle
        </b-form-checkbox>
      </div>
      
      <div>
        <b-button
          size="small"
          variant='primary'
          :disabled="!screenShotSize || !aspectRatio || aspectX === 0 || aspectY === 0 || !resolution || !screenshotType"
          @click='captureScreenshot'>
          Capture
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenShotControls',
  props: {
    aspectX: {
      type: Number,
      required: true
    },
    aspectY: {
      type: Number,
      required: true
    },
    screenShotSize: {
      required: true
    },
    onSavedCamera: {
      required: true
    }
  },
  data() {
    return {
      aspectRatio: null,
      resolution: null,
      screenshotType: null,
      saveCamera: false
    };
  },
  methods: {
    setAspectX(x) {
      this.$emit('setAspectX', x);
    },
    setAspectY(y) {
      this.$emit('setAspectY', y);
    },
    setScreenShotSize(size) {
      this.$emit('setScreenShotSize', size);
    },
    captureScreenshot() {
      switch (this.screenshotType) {
      case 'projectImage':
        this.setProjectImage();
        break;
      case 'currentLayer':
        this.saveScreen();
        break;
      case 'allLayers':
        this.screenshotAllSelectedLayers();
        break;
      default:
        break;
      }
    },
    saveScreen() {
      this.$emit('saveScreen', this.saveCamera);
    },
    setProjectImage() {
      this.$emit('setProjectImage');
    },
    screenshotAllSelectedLayers(){
      this.$emit('takeMultipleScreenshots', true);
    },
    toggleScreenShotMode() {
      this.$emit('toggleScreenShotMode');
    }
  },
  watch: {
    aspectRatio(newValue) {
      if(newValue && newValue !== 'custom') {
        let aspect = newValue.split(':');
        this.setAspectX(aspect[0]);
        this.setAspectY(aspect[1]);
      }
    },
    onSavedCamera(newValue) {
      if(newValue) this.saveCamera = false;
    }
  },
  computed: {
    isSplitView() {
      return !!this.$route.query?.secondary;
    }
  },
};
</script>

<style scoped>
#screenshot-controls-container {
  background: rgba(200,200,200,0.5);
  border-radius: 10px;
}

.aspect-ratio-input {
  display: inline;
  width: 3.375rem;
}

.aspect-ratio-colon {
  font-size: 1.5rem;
}

.screenshot-dropdown {
  padding-top: 0;
  padding-bottom: 0;
  width: 8rem;
}

</style>
<style>
.save-camera-checkbox .custom-control-input {
  position: absolute;
}
</style>
